import React from "react";

function Loader({ loading }) {
	const isDNA = window.location?.href?.split('/')[2] === 'views.diadna.com' ? true : false;

	if (loading) {
		return (
			<div
				id="loader"
				className={`${isDNA ? "loading-dna-indicator" : "loading-indicator"}`}
			>
				<div id={`${isDNA ? "dna-loader-inner" : "loader-inner"}`}>
					{!isDNA ? <img src={require("../../assets/img/loader.svg")} alt="Finestar" /> : null}
				</div>
			</div>
		);
	}
	return null;
}

export default Loader;