import React from "react";
import ReactDOM from "react-dom";

import Root from "./config/Root";

import "styles/App.less";
import "styles/DiamondList.less";
import "styles/global.less";

ReactDOM.render(
	<React.StrictMode>
		<Root />
	</React.StrictMode>,
	document.getElementById("root"),
);
