import React, { Suspense, lazy } from "react";
import { BrowserRouter as Router, Switch, Route, Redirect } from "react-router-dom";

import Loader from "components/common/Loader";

const OtpVerify = lazy(() => import("components/OtpVerify"));
const RoughAuction = lazy(() => import("components/RoughAuction"));
const PageNotFound = lazy(() => import("components/PageNotFound"));

function App() {
	return (
		<Suspense fallback={<Loader />}>
			<Router>
				<Switch>
					<Route exact path="/" render={() => <Redirect to="/not-found" />} />
					<Route path="/not-found" component={PageNotFound} />
					<Route path="/rough-auction/:id*" component={RoughAuction} />
					<Route exact path="/:id*" component={OtpVerify} />
				</Switch>
			</Router>
		</Suspense>
	);
}

export default React.memo(App);
